// Breakpoints
// TODO, casing for nested props
export const BREAKPOINTS = {
  small_only: "(max-width: 767px)",
  small: "(min-width: 768px)",
  medium: "(min-width: 992px)",
  large: "(min-width: 1300px)"
};

// MQ for CSSinJS
export const MEDIA_QUERIES = {
  SMALL_ONLY: `@media only screen and ${BREAKPOINTS.small}`,
  SMALL: `@media only screen and ${BREAKPOINTS.small}`,
  MEDIUM: `@media only screen and ${BREAKPOINTS.medium}`,
  LARGE: `@media only screen and ${BREAKPOINTS.large}`
};
