import React from "react";
import styled from "@emotion/styled";
import { gutter } from "utils/mixins";

const ContactLinks = styled.div({}, gutter({}));
const LinkList = styled.div({
  display: "flex"
});
const Link = styled.a(
  {
    display: "block",

    color: "black",

    ":hover": {
      color: "DarkSlateGray",
      textDecoration: "none"
    }
  },
  gutter({ top: 0, left: 0, bottom: 0, right: 1 })
);

const ContactLinksComponent = () => (
  <ContactLinks>
    <LinkList>
      <Link href="https://linkedin.com/in/loque/">LinkedIn</Link>
      <Link href="https://github.com/Loque-">GitHub</Link>
      <Link href="https://www.npmjs.com/~loque-">npm</Link>
    </LinkList>
  </ContactLinks>
);

export default ContactLinksComponent;
