/**
 * @fileOverview CSSinJS mixins for Emotion
 *
 * @Todo Write tests (yes!)
 * @todo Publish to NPM (yes!yes!)
 * @Todo Consider making mixin support different units instead of just px (meh)
 * @todo Replace lodash dep with internal function? (meh)
 *
 * @requires lodash.isEqual()
 *
 * @author Thorbjorn Brancher
 *
 */
import { isEqual } from "lodash";
import { BREAKPOINTS } from "constants/media";
import { GUTTERS } from "constants/spacing";

/**
 * @summary Creates a CSS padding compatible string value from pvided gutter and object of multipliers
 * @function
 * @private
 *
 * @description
 * This function was created for the gutter CSSinJS mixin
 *
 * @param {Number} gutter - Gutter value, e.g: 20.
 * @param {Object} multipliers - an object containing a property and value pair for each required padding value
 *
 * @returns {String} A CSS compatible string value for the `padding` property
 *
 */
function buildPadding(
  gutter,
  { top = 0.5, right = 0.5, bottom = 0.5, left = 0.5 } = {}
) {
  const pTop = gutter * top;
  const pRight = gutter * right;
  const pBottom = gutter * bottom;
  const pLeft = gutter * left;

  return `${pTop}px ${pRight}px ${pBottom}px ${pLeft}px`;
}

/**
 * @summary Creates gutters, using padding, from BREAKPOINTS and GUTTER constants for Emotion Object syntax
 * @function
 * @requires lodash.isEqual()
 * @requires buildPadding()
 * @public
 *
 * @description
 * Manage your gutters and breakpoints globally, allowing the spacing in your application to scale relative to these.
 *
 * @param {Object} defaultMultipliers - top, right, bottom, left multiplier value, e.g: {top: 1}
 * @param {Object} overrides - Specific properties for specific breakpoints matched within your GUTTERS constant, e.g: { medium: {top: 2} }
 *
 * @returns {Object} An emotion compatible object with CSS rules for padding
 *
 * @example
 * import styled from '@emotion/styled';
 * import { gutter } from 'utils/mixins';
 *
 * const SimpleExample = styled.div(
 *     {
 *         background: 'hotpink'
 *     },
 *     gutter()
 * )
 *
 * const ComplexExample = styled.div(
 *     {
 *         background: 'hotpink'
 *     },
 *     gutter(
 *         { right: 0 },
 *         {
 *             small: {
 *                 top: 0,
 *                 right: 0.5,
 *             }
 *             medium: {
 *                 top: 2,
 *             }
 *         }
 *     )
 * );
 *
 */
export function gutter(defaultMultipliers = {}, overrides = {}) {
  // The object we append to and return
  let gutters = {
    // Create our default padding
    padding: buildPadding(GUTTERS.default, defaultMultipliers)
  };
  // A reference to the last applied padding value to avoid duplicate/unwanted rules
  let lastPad = gutters.padding;
  // Last multipliers used to ensure previous breakpoint rules are preserved
  let lastMultipliers = {
    ...defaultMultipliers
  };
  // Loop through BREAKPOINTS and create padding rule if required
  for (const point in BREAKPOINTS) {
    const breakpointVal = BREAKPOINTS[point];
    const gutterVal = GUTTERS[point];

    // If we don't have a matching gutter for that breakpoint, ignore it
    if (gutterVal === undefined) {
      continue;
    }

    // Merge last used multipliers, and specific multipliers together (if there are any...)
    const multipliers = {
      ...lastMultipliers,
      ...overrides[point]
    };

    // Update lastMultipliers if it differs so they are carried forward to the next breakpoint
    if (isEqual(lastMultipliers, multipliers) === false) {
      lastMultipliers = { ...lastMultipliers, ...multipliers };
    }

    // Create padding for breakpoint
    const padding = buildPadding(gutterVal, multipliers);

    // Would be nice to do something additionally here with last rules applied, for example;
    // - If the padding value changes, but the specified override is preserved
    // console.log(padding !== lastPad, padding, lastPad, overrides[point]);

    // Compare against the last padding rule applied
    if (padding !== lastPad) {
      // Append gutter to gutters object
      gutters[`@media${breakpointVal}`] = {
        padding
      };
    }

    // Keep track of the last padding applied
    lastPad = padding;
  }

  return gutters;
}
