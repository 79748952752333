import React from "react";
import styled from "@emotion/styled";

const SectionTitle = styled.div({
  fontWeight: "600",
  textTransform: "uppercase",
  marginBottom: "8px"
});

const SectionTitleComponent = ({ children }) => (
  <SectionTitle>{children}</SectionTitle>
);

export default SectionTitleComponent;
