import React from "react";
import styled from "@emotion/styled";

const Logo = styled.div({});

const Name = styled.h1({
  fontWeight: "300",
  textTransform: "uppercase"
});
const Context = styled.div({
  fontWeight: "300",
  letterSpacing: "0.8px"
});

const LogoComponent = () => (
  <Logo>
    <Name>Toby Brancher</Name>
    <Context>ReactJS UI Architect & Developer</Context>
  </Logo>
);

export default LogoComponent;
