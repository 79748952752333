import React from "react";
import styled from "@emotion/styled";
import SectionTitle from "components/molecules/sectionTitle";
import { gutter } from "utils/mixins";

const Introduction = styled.div({}, gutter());
const Text = styled.div({
  //   fontWeight: "300"
});

const IntroductionComponent = () => (
  <Introduction>
    <SectionTitle>Introduction</SectionTitle>
    <Text>
      <p>
        Senior UI Developer specialising in ReactJS development with specific
        design and technology principles to ensure best-in-class solutions
        whilst catering for performance, creativity and maintainability.
      </p>
      {/* <p>
        I keep up-to-date with my domain, watching and attending conferences as
        well as keeping in touch with experienced industry leaders to learn from
        what they are doing.
      </p> */}
    </Text>
  </Introduction>
);

export default IntroductionComponent;
