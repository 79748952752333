import React from "react";
import styled from "@emotion/styled";
import SectionTitle from "components/molecules/sectionTitle";
import TagList from "components/molecules/tagList";
import { gutter } from "utils/mixins";

const Experience = styled.div({}, gutter());

const ExperienceComponent = () => (
  <Experience>
    <SectionTitle>Experience</SectionTitle>
    <TagList>
      Rapid Application Development, TDD, Open Source, Monorepos, Storybook,
      Pattern Libraries, UI Architecture, Managing Teams & Individuals,
      Performance Analysis, Public Speaking, FE Tooling, Agile, SAFe,
      Prototyping, Analytics
    </TagList>
  </Experience>
);

export default ExperienceComponent;
