import React from "react";
import ReactDOM from "react-dom";
import Header from "components/organisms/header";
import Body from "components/organisms/body";
import Introduction from "components/organisms/introduction";
import ContactLinks from "components/organisms/contactLinks";
import Experience from "components/organisms/experience";
import Technologies from "components/organisms/technologies";

import "./styles.css";

function App() {
  return (
    <div className="App">
      <Header />
      {/* <Body>
        <Introduction />
        <Technologies />
        <Experience />
        <ContactLinks />
      </Body> */}
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
