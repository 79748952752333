import React from "react";
import styled from "@emotion/styled";
import { gutter } from "utils/mixins";

const Body = styled.div(
  {
    // borderBottom: 'solid 1px '
  },
  gutter({ top: 1, bottom: 1 })
);

const BodyComponent = ({ children }) => <Body>{children}</Body>;

export default BodyComponent;
