import React from "react";
import styled from "@emotion/styled";
import SectionTitle from "components/molecules/sectionTitle";
import TagList from "components/molecules/tagList";
import { gutter } from "utils/mixins";

const Technologies = styled.div({}, gutter());

const TechnologiesComponent = () => (
  <Technologies>
    <SectionTitle>Technologies & Principles</SectionTitle>
    <TagList>
      React, Atomic Design, GraphQL, Performance, Hardware Accelerated
      Transitions, Open Source, npm, Kanban
    </TagList>
  </Technologies>
);

export default TechnologiesComponent;
