import React from "react";
import styled from "@emotion/styled";
import Logo from "components/molecules/logo";
import ContactLinks from "components/organisms/contactLinks";
import { gutter } from "utils/mixins";

const Header = styled.div(
  {
    // borderBottom: "solid 1px #434449"
  },
  gutter()
);
const LogoContainer = styled.div(
  {
    borderBottom: "solid 1px #434449"
  },
  gutter({ top: 1, bottom: 1 })
);

const HeaderComponent = () => (
  <Header>
    <LogoContainer>
      <Logo />
    </LogoContainer>

    <ContactLinks />
  </Header>
);

export default HeaderComponent;
